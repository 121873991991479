import React, { useState } from "react";

import config from "../../../../config";

import './us.scss'
import './adaptive.scss'


function Us() {
    const [page, setPage] = useState(1);
    return (
        <React.Fragment>
                <div className="aboutUs">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <div className="title">
                                    <span>О компании</span>
                                    <span><b>{config.company} —</b><br/>надежный партнер вашего бизнеса</span>
                                </div>
                                <div className="description">
                                Продажа и доставка сыпучих строительных материалов – приоритетная деятельность нашей компании. За время существования нам удалось разработать универсальную схему покупки и доставки реализуемой продукции: покупатель оставляет заявку на приобретение сырья, наши специалисты обрабатывают заказ в тот же день
                                </div>
                            </div>
                            <div className="line"/>
                        </div>
                    </div>
                    <div className="slider">
                        <div className="container">
                            <div className="content">
                                <div className="btns">
                                    <div className={page === 1 ? "btn active" : "btn"} onClick={()=>{setPage(1)}}>Известняковый щебень</div>
                                    <div className={page === 2 ? "btn active" : "btn"} onClick={()=>{setPage(2)}}>Доломитовый шебень</div>
                                    <div className={page === 3 ? "btn active" : "btn"} onClick={()=>{setPage(3)}}>ЩПС - Щебеночно песчаная смесь</div>
                                </div>
                                <div className="pages">
                                    {
                                        page === 1 ? (
                                            <>
                                                <span className="title">Известняковый щебень</span>
                                                <p>Известняковый щебень – это строительный материал, добывающийся из осадочной горной породы – известняка, состоящего преимущественно из карбоната кальция.</p>
                                                <p>Производство известнякового щебня осуществляется в результате переработки или дробления известняка. Вся технология его изготовления основывается на нормативном документе, которым является ГОСТ 8267-93. </p>
                                                <span className="title">Применение известнякового щебня</span>
                                                <p>В основном данный строительный материал используется в полиграфической и стекольной промышленности. С участием его изготавливаются ЖБИ, производится цемент. Не обходится без этого компонента и строительство дорог с невысокой транспортной нагрузкой. А с достаточно плотной структурой его применяют и для устройства дренажей.</p>
                                                <span className="title">Какие основные характеристики щебня известнякового?</span>
                                                <p>Данная продукция имеет следующие свойства: прочностью, морозостойкостью, лещадностью, уровнем радиоактивности, плотностью и делением на фракции.</p>
                                                <div className="table">
                                                    <div className="item">
                                                        <span>Морозостойкость</span>
                                                        <span>от F-50 до F-150</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Содержание зерен слабых пород в % по массе</span>
                                                        <span>5-9 %</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Марка прочности</span>
                                                        <span>М400, М600 и М800</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Насыпная плотность</span>
                                                        <span>от 1200 до 1350 кг / м3,</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Удельная эффективная активность</span>
                                                        <span>54,8 +-15,1 БК / кг</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Влажность</span>
                                                        <span>до 5%</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Пористость</span>
                                                        <span>(5,3-7,4%)</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Водопоглощение</span>
                                                        <span>2,6%</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Содержание пылевидных и глинистых частиц</span>
                                                        <span>1,5-3 % ( 0 -0,22% )</span>
                                                    </div>
                                                </div>
                                                <span className="title">Различают всего три вида фракции продукции из известняка:Фракции известнякового щебня</span>
                                                <div className="numberText">
                                                    <span>• 5-20 мм служит в промышленности как наполнитель для изготовления бетона и железобетонных конструкций;</span>
                                                    <span>• 20-40 мм используется чаще всего в строительстве и укладке фундамента;</span>
                                                    <span>• 40-70 мм незаменим для создания больших железобетонных сооружений.</span>
                                                </div>
                                            </>
                                        ): page === 2 ? (
                                            <>
                                                <span className="title">Доломитовый щебень</span>
                                                <p>Доломитовый щебень – стройматериал органического происхождения (карбонат кальция), который добывают, дробя горный доломит. Он образуется при воздействии на известняк природных вод (как осадочная порода). Это разновидность известняка, но эксплуатационные характеристики и состав разнятся. Грани доломитового щебня блестят, на них видны узоры, он может быть белым, серым и других тонов, поэтому его применяют в качестве декоративного материала. Если в породе меньше 3/4 кальцита, ее считают доломитовым известняком, а если его больше, то породу относят к известняковому доломиту.</p>
                                                <span className="title">Виды и сферы применения</span>
                                                <p>Благодаря неплохой прочности, стойкости к перепадам температур, долговечности, низкому коэффициенту водопоглощения и низкой радиоактивности, а также огнеупорности и жаростойкости, область применения измельченного доломита широка:</p>
                                                <div className="numberText">
                                                    <span>• гранит, имеющий фракцию от 5 до 20 мм, служит сырьем для создания бетона. Если вы собираетесь купить доломитовый щебень, то следует учесть, что его используют в производстве балок, перекрытий, для заливки полов, которые рассчитаны на монтаж тяжеловесного оборудования;</span>
                                                    <span>• из камня, имеющего зернистость от 20 до 40 мм, возводят фундаменты. Также гранит используют как наполнитель для бетона, при засыпке дорожек в парках, аллеях и на площадках, при оформлении водопадов и других элементов ландшафтного дизайна на дачах и возле загородных коттеджей;</span>
                                                    <span>• доломитовый щебень, имеющий размеры 40-70 и 70-120 мм, необходим для подсыпки подушек на объектах транспортной инфраструктуры.<br/> Прочность материала сравнима с прочностью гравия или гранита.</span>
                                                </div>
                                                <div className="table">
                                                    <div className="item">
                                                        <span>Прочность</span>
                                                        <span>М600-М800</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Лещадность</span>
                                                        <span>до 10%</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Морозостойкость</span>
                                                        <span>F100</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Насыпная плотность</span>
                                                        <span>1300 кг/м3</span>
                                                    </div>
                                                    <div className="item">
                                                        <span>Класс радиоактивности</span>
                                                        <span>1</span>
                                                    </div>
                                                </div>
                                            </>
                                        ): page === 3 ? (
                                            <>
                                                <span className="title">ЩПС</span>
                                                <p>ЩПС представляет собой стройматериал природного происхождения, состоящий из двух составляющих: щебня и песка. Производится двумя способами. Первый – путем природного разрушения скальной породы. Второй метод – искусственное обогащение.</p>
                                                <p>В зависимости от размера зерен, смесь имеет несколько фракций. Марка используемого в составе щебня должна быть не менее М400. Насыпная объемная масса определяется для каждой партии. Щебеночно-песчаный состав не должен включать обломки щебня и элементы арматуры.</p>
                                                <span className="title">Преимущества</span>
                                                <p>Смесь из щебня и песка имеет массу преимуществ перед остальными стройматериалами. Главным плюсом можно считать практически бесконечное хранение, абсолютную нейтральность к погодным условиям и возможность применения в любое время года.</p>
                                                <span className="title">ГОСТ щебеночно-песчаной смеси</span>
                                                <p>Зерновой состав, водоустойчивость, пластичность, морозостойкость и другие физико-технические характеристики готовой ЩПС соответствуют ГОСТ 25607.</p>
                                                <span className="title">Область применения</span>
                                                <p>Главная область потребления ЩПС – дорожное строительство, где продукт используется для производства дорожного покрытия. Стройматериал незаменим при устройстве дорог, стоянок и площадок.</p>
                                                <p>Доступная стоимость позволяет использовать смесь в ландшафтных работах при необходимости выравнивания грунта, обратной засыпке и осушении территорий.</p>
                                                <span className="title">Цена ЩПС</span>
                                                <p>Щебеночно-песчаная смесь производится быстро, поэтому имеет невысокую стоимость. Стоимость обогащенной ЩПС выше, чем у природной, что обусловлено затратами на дробление и сортировку. Приобретая продукт у производителя, гарантируется невысокая стоимость, стабильность отгрузки и высокое качество стройматериала.</p>
                                            </>
                                        ) :   setPage(1)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
}

export default Us;