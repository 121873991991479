const productsArray = {
    main: [
        {
            title: 'Известняковый щебень',
            descriptor: 'фракции 5-20',
            price: 520
        },
        {
            title: 'Известняковый щебень',
            descriptor: 'фракции 20-40',
            price: 500
        },
        {
            title: 'Известняковый щебень',
            descriptor: 'фракции 40-70',
            price: 480
        },
        {
            title: 'Доломитовый щебень',
            descriptor: 'фракции 5-20',
            price: 520
        },
        {
            title: 'Доломитовый щебень',
            descriptor: 'фракции 20-40',
            price: 500
        },
        {
            title: 'Доломитовый щебень',
            descriptor: 'фракции 40-70',
            price: 480
        },
        {
            title: 'Известняковый отсев',
            descriptor: 'фракции 0-5',
            price: 70
        },
        {
            title: 'Известняковый щебень в мешках',
            descriptor: 'Биг бегах',
            price: 520
        },
        {
            title: 'ЩПС',
            descriptor: 'С-1',
            price: 700
        },
        {
            title: 'ЩПС',
            descriptor: 'С-2',
            price: 700
        },
        {
            title: 'ЩПС',
            descriptor: 'С-3',
            price: 700
        },        {
            title: 'ЩПС',
            descriptor: 'С-4',
            price: 700
        },
        {
            title: 'ЩПС',
            descriptor: 'С-5',
            price: 700
        },
        {
            title: 'ЩПС',
            descriptor: 'С-6',
            price: 700
        },        {
            title: 'ЩПС',
            descriptor: 'С-7',
            price: 700
        },
        {
            title: 'ЩПС',
            descriptor: 'С-8',
            price: 700
        },
        {
            title: 'ЩПС',
            descriptor: 'С-9',
            price: 700
        },        {
            title: 'ЩПС',
            descriptor: 'С-10',
            price: 700
        },
    
    ],
}
export default productsArray