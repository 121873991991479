const config = {
    url: 'https://изв-щебень.рф', 
    company: 'ООО «Нерудная Компания»',
    meta_title: 'Известняковый щебень с доставкой по Москве и МО - 24/7',
    meta_description: 'Известняковый щебень напрямую от производителя предлагает компания ООО «Нерудная Компания» . У нас свой автопарк. Любая форма оплаты. Звони!',
    title: 'Известняковый щебень',
    descriptor: 'напрямую от производителя',
    offer: 'Мы поставляем качественный известняковый щебень разных фракций по низким ценам',
    time: 'Пн-Вс 8:00 — 23:00',
    phone: '+7(926) 452 03 90',
    email: 'info@nerudkom.ru',
    INN: '5003140340',
    OGRN: '1205000062321',
    whatsapp: '+79263900390',
    telegram: 'nerudkom_support_bot',
    bitrix24WebHook: 'https://alfaspetsstroy.bitrix24.ru/rest/920/l4tfx5w6qcwi28zh',
    pole_phone: 'UF_CRM_2928E19C70542',
    pole_name: 'UF_CRM_1623227175451',
    pole_value: 'UF_CRM_1623224263',
    pole_address: 'UF_CRM_1628014047977',
    CATEGORY_ID: 2,
    funnel_id: '2'
}

export default config